import { Facebook, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import React from "react";
import "../stylesheets/FooterComponent.css";
import Links from "../../data/Links.json";

function FooterComponent() {
  const {
    LejhroBootcampURL,
    LejhroInnovationsURL,
    LejhroContactUsURL,
    LejhroTermsOfUseURL,
    LejhroPrivayStatementURL,
    LejhroBlogsURL,
    LejhroAboutURL,
    LejhroServicesURL,
    LejhroTwitter,
    LejhroFacebook,
    LejhroInstagram,
    LejhroYoutube
  } = Links;

  return (
    <div className="footer-main-div" id="bootcamp-footer">
      <div className="footer-header" />
      <div className="footer-body">
        <div className="footer-body-container">
          <div className="footer-section">
            <h3>Company</h3>
            <a href={LejhroInnovationsURL} target="_blank" rel="noreferrer">
              Innovations
            </a>
            <a href={LejhroServicesURL} target="_blank" rel="noreferrer">
              Services
            </a>
            <a href="http://www.recruit.lejhro.com/">Lejhro Recruiter</a>
            <a href={LejhroAboutURL} target="_blank" rel="noreferrer">
              About
            </a>
            <a href={LejhroBlogsURL} target="_blank" rel="noreferrer">
              Blogs
            </a>
          </div>
          <div className="footer-section">
            <h3>Programs</h3>
            <a href={LejhroBootcampURL} target="_blank" rel="noreferrer">
              Lejhro Bootcamp
            </a>
          </div>
          <div className="footer-section">
            <h3>Support</h3>
            <a href={LejhroContactUsURL} target="_blank" rel="noreferrer">
              Contact
            </a>
            <a href={LejhroTermsOfUseURL} target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            <a href={LejhroPrivayStatementURL} target="_blank" rel="noreferrer">
              Privacy Statement
            </a>
          </div>
          <div className="footer-section">
            <h3>Connect with us</h3>
            <div className="footer-section-connect">
              <a href={LejhroTwitter} target="_blank" rel="noreferrer" aria-label="Twitter">
                <Twitter style={{ fontSize: "25px" }} />
              </a>
              <a href={LejhroFacebook} target="_blank" rel="noreferrer" aria-label="Facebook">
                <Facebook style={{ fontSize: "25px" }} />
              </a>
              <a href={LejhroInstagram} target="_blank" rel="noreferrer" aria-label="Instagram">
                <LinkedIn style={{ fontSize: "25px" }} />
              </a>
              <a href={LejhroYoutube} target="_blank" rel="noreferrer" aria-label="Youtube">
                <YouTube style={{ fontSize: "25px" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="footer-copyright">
        © {new Date().getFullYear()} LEJHRO. All Rights Reserved.
      </p>
    </div>
  );
}

export default FooterComponent;
