import React, { useState } from "react";
import "../stylesheets/TopHeader.css";
import SideDrawer from "./SideDrawer";
import { Button } from "antd";
import Links from "../../data/Links.json";

const TopHeader = () => {
  const {
    LejhroBootcampURL,
    LejhroInnovationsURL,
    LejhroServicesURL
  } = Links;
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <div className="top-header-header-div">
      <SideDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <div className="top-header-header-logo-div">
        <a href="/">
          <img
            src="/lejhro_logo_white.png"
            alt=""
            className="top-header-fixed-logo"
          />
        </a>
        {/* <CourseDropdown/> */}
      </div>
      <div className="top-header-links-div">
        <a href={LejhroInnovationsURL} target="_blank"
              rel="noreferrer">Innovations</a>
        <a href={LejhroServicesURL} target="_blank"
              rel="noreferrer">Services</a>
        <a href={LejhroBootcampURL} target="_blank"
              rel="noreferrer">Bootcamp</a>
        <Button
        onClick={() => {
          setDrawerOpen(true);
        }}
        className="top-header-drawer-button-links"
      >
          <div className="side-drawer-button-container-div-line1"/>
          <div className="side-drawer-button-container-div-line2"/>
          <div className="side-drawer-button-container-div-line1"/>
      </Button>
      </div>
      <Button
        onClick={() => {
          setDrawerOpen(true);
        }}
        className="top-header-drawer-button"
      >
          <div className="side-drawer-button-container-div-line1"/>
          <div className="side-drawer-button-container-div-line2"/>
          <div className="side-drawer-button-container-div-line1"/>
      </Button>
    </div>
  );
};

export default TopHeader;
