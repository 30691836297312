import React from "react";
import "./RecruitPage.css";
import TopHeader from './../../common/components/TopHeader';

function RecruitPage() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={`BuildYourFutureWorkspace-div`} id="page4">
      <TopHeader/>
      <div className="BuildYourFutureWorkspace-content">
      <div className="BuildYourFutureWorkspace-inside-div">
        <h1 className="homepage-heading">Build Your Future Workforce</h1>
        </div>
        <div
          className="BuildYourFutureWorkspace-button"
          onClick={() => {
            openInNewTab("https://www.lejhro.com/contact-us");
          }}
        >
          Contact Us
        </div>
      </div>
      <div className="BuildYourFutureWorkspace-img">
        <img src={`images/homepage-backgound.png`} alt="" />
      </div>
      <div className="BuildYourFutureWorkspace-img-mobile">
        <img src={`images/homepage-background-mobile.png`} alt="" />
      </div>
    </div>
  );
}

export default RecruitPage;
