import { Button } from "antd";
import React, { useState } from "react";
import "../stylesheets/TopFixedHeader.css";
import SideDrawer from "./SideDrawer";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Links from "../../data/Links.json";

const TopFixedHeader = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isNavBarFixed, setNavBarFixed] = useState(false);
  const { LejhroContactUsURL } = Links;
  const changeClass = () => {
    if (window.scrollY >= 100) {
      setNavBarFixed(true);
    } else {
      setNavBarFixed(false);
    }
  };

  window.addEventListener("scroll", changeClass);

  const openDrawer = () => {
    setDrawerOpen(true);
    // document.body.style.overflow = 'unset';
  };
  return (
    <div
      className={isNavBarFixed ? "top-fixed-header" : "top-fixed-header-hidden"}
    >
      <SideDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <a href="/" aria-label="HomePage">
        <img
          src="/lejhro_logo_violet.png"
          alt="Lejhro Logo"
          className="top-fixed-header-fixed-logo"
        />
      </a>
      <div className="top-fixed-header-container">
        <div className="top-fixed-header-contact-div paragraph-small-text">
          <a
            href={LejhroContactUsURL}
            target="_blank"
            rel="noreferrer"
          >
            <span>Let's Talk</span> <ArrowRightAltIcon />
          </a>
        </div>

        <Button
          onClick={() => {
            // setDrawerOpen(true);
            openDrawer();
          }}
          className="top-fixed-header-drawer-button"
        >
          <div className="side-drawer-button-container-div-line1" />
          <div className="side-drawer-button-container-div-line2" />
          <div className="side-drawer-button-container-div-line1" />
        </Button>
      </div>
    </div>
  );
};

export default TopFixedHeader;
