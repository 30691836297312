import React, {useEffect} from "react";
import "./App.css"
import RecruitPage from "./pages/RecruitPage/RecruitPage";
import WhyLejhro from "./pages/WhyLejhroPage/WhyLejhro";
import ScrollToTop from "./common/components/ScrollToTop";
import FooterComponent from "./common/components/FooterComponent";
import TopFixedHeader from './common/components/TopFixedHeader';
import {HelmetProvider, Helmet } from 'react-helmet-async';
import AOS from 'aos';

function App() {
  useEffect(() => {
    AOS.init(
      {
        once: true,
        delay: 12,
        throttleDelay: 99
      }
    );
  }, [])
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lejhro Recruiter | Tap our Talents</title>
        <meta name="description" content="Join us in building a better future by tapping into our talent pool. Let's create meaningful career opportunities and develop a world-class workforce together." />
        <meta name="keywords" content="Careers"/>
      </Helmet>
      <ScrollToTop />
      <TopFixedHeader/>
      <RecruitPage />
      <WhyLejhro/>
      <FooterComponent />
    </HelmetProvider>
  );
}

export default App;
