import React from "react";
import "./WhyLejhro.css";

function WhyLejhro() {
  return (
    <div className="why-lejhro-content">
      <h2 className="h2 white-heading h2-1">Why Lejhro Learners?</h2>
      <div className="why-lejhro-content-left" id="why-lejhro-content-left">
        <h2 className="h2 white-heading h2-2">Why Lejhro Learners?</h2>
        <p className="p-bold text-black bullet-point">
          <img
            src="/images/text-icon.png"
            alt="bullet"
            className="why-lejhro-point-icon"
          />
          Industry Ready Candidates
        </p>
        <p className="p-bold  text-black bullet-point">
          <img
            src="/images/text-icon.png"
            alt="bullet"
            className="why-lejhro-point-icon"
          />
          Diverse Skill Sets and Capabilities
        </p>
        <p className="p-bold text-black bullet-point">
          <img
            src="/images/text-icon.png"
            alt="bullet"
            className="why-lejhro-point-icon"
          />
          Groomed by Experts
        </p>
        <p className="p-bold text-black bullet-point">
          <img
            src="/images/text-icon.png"
            alt="bullet"
            className="why-lejhro-point-icon"
          />
          Trusted By Industry Pioneers
        </p>
      </div>
      <div className="why-lejhro-content-right" id="why-lejhro-content-right">
        <img src="images/why-lejhro.png" alt="Lejhro" />
      </div>
    </div>
  );
}

export default WhyLejhro;
