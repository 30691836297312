import React,{useState} from 'react'
import "../stylesheets/ScrollToTop.css"

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from 'antd';
const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  const changeClass = () => {
    if (window.scrollY >= 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  window.addEventListener("scroll", changeClass);

  return (
    <Button className={isVisible ? 'scroll-to-top' :'scroll-to-top-hidden'} onClick={()=>{window.scrollTo({top:0, behavior: 'smooth'})}} aria-label="Scroll To Top">
      <KeyboardArrowUpIcon fontSize="large" className="scroll-to-top-icon"/>
    </Button>
  )
}

export default ScrollToTop

